import styled from "styled-components";
export const FooterWrapper = styled.div`
  .footer {
    background-color: #393939;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
  }
  .footer-link {
    display: flex;
    gap: 10px;
    justify-content: end;
  }
  @media (max-width: 768px) {
    .footer-link {
      justify-content: center;
    }
  }
`;
