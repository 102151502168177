import { Button, Form, Radio, RadioChangeEvent, DatePicker } from "antd";
import { useAppDispatch } from "Hooks/Store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loading as stateLoading, user } from "Redux/App";
import { SoftSkillsAction } from "Redux/App/Actions/Items";
import { useState } from "react";

import {
  WellComeBoxActaurial,
  DashBoardWrapper,
  MainHeading,
  SecondaryHeading,
  RadioButtonTitle,
  SoftSkillOuterWrapper,
} from "../styles";
import { Moment } from "moment";
import { Checkbox } from "antd";
import { useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const Home = () => {
  const loading = useSelector(stateLoading);
  const userData = useSelector(user);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [softwares, setSoftwares] = useState([
    {
      label: "Excel",
      value: "Excel",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Word",
      value: "Word",
      isSelected: false,
      grading: 0,
    },
    {
      label: "PowerPoint",
      value: "PowerPoint",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Power BI",
      value: "Power BI",
      isSelected: false,
      grading: 0,
    },
    {
      label: "C",
      value: "C",
      isSelected: false,
      grading: 0,
    },
    { label: "ResQ", value: "ResQ", isSelected: false, grading: 0 },
    { label: "Psicle", value: "Psicle", isSelected: false, grading: 0 },
    { label: "InsurSight", value: "InsurSight", isSelected: false, grading: 0 },
    {
      label: "R",
      value: "R",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Python",
      value: "Python",
      isSelected: false,
      grading: 0,
    },
    {
      label: "VB",
      value: "VB",
      isSelected: false,
      grading: 0,
    },
    {
      label: "VBA",
      value: "VBA",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Emblem",
      value: "Emblem",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Radar",
      value: "Radar",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Tyche",
      value: "Tyche",
      isSelected: false,
      grading: 0,
    },
    {
      label: "RMS",
      value: "RMS",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Prophet",
      value: "Prophet",
      isSelected: false,
      grading: 0,
    },
    {
      label: "Moses",
      value: "Moses",
      isSelected: false,
      grading: 0,
    },
  ]);
  const [ir35, setIR35] = useState(null);
  const [availability, setAvailability] = useState("");
  const [date, setDate] = useState<Moment>();

  useEffect(() => {
    if (["softwares", "ir35", "availability"].every((key) => key in userData)) {
      const softwaresList = JSON.parse(userData["softwares"]);
      softwaresList.forEach((software: any) => {
        const newSoftwares = [...softwares];
        const index = newSoftwares.findIndex(
          (item) => item.value === software.label
        );
        newSoftwares[index].isSelected = true;
        newSoftwares[index].grading = software.grading;
        setSoftwares(newSoftwares);
      });
      // setSoftwares(JSON.parse(userData["softwares"]));
      setIR35(userData["ir35"]);
      if (userData["availability"] === "Available now") {
        setAvailability(userData["availability"]);
      } else {
        setAvailability("date");
        form.setFieldsValue({
          date: moment(userData["availability"]),
        });
      }
    }
  }, []);

  function onChangeSoftware(event: any, index: number) {
    const newSoftwares = [...softwares];
    newSoftwares[index].isSelected = event.target.checked;
    setSoftwares(newSoftwares);
  }
  const onChangeGrading = (e: RadioChangeEvent, softwareIndex: number) => {
    console.log("radio checked", e.target.value);
    const newSoftwares = [...softwares];
    newSoftwares[softwareIndex].grading = e.target.value;
    setSoftwares(newSoftwares);
    console.log("softwares = ", newSoftwares);
  };
  const onChangeIR35 = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setIR35(e.target.value);
  };
  const onChangeAvailability = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setAvailability(e.target.value);
  };
  const onFinish = (values: any) => {
    const selectSoftwares: {}[] = [];
    softwares.forEach((software) => {
      if (software.isSelected) {
        selectSoftwares.push(software);
      }
    });
    values["userID"] = userData?.userID;
    values["softwares"] = JSON.stringify(selectSoftwares);
    values["ir35"] = ir35;
    values["availability"] = availability === "date" ? date : availability;
    console.log("Success:", values);
    dispatch<any>(SoftSkillsAction(values, navigate));
  };

  return (
    <SoftSkillOuterWrapper>
      <DashBoardWrapper>
        <WellComeBoxActaurial>
          <div className="parentDiv">
            <div className="main-btn">
              <Link to="/portal/personal-information" className="nav-links">
                <Button
                  className="lets-navigate "
                  htmlType="submit"
                  loading={loading}
                >
                  Personal Information
                </Button>
              </Link>
              <Link to="/portal/expertise" className="nav-links">
                <Button
                  className="lets-navigate "
                  htmlType="submit"
                  loading={loading}
                >
                  Expertise
                </Button>
              </Link>
              <Link to="/portal/soft-skill" className="nav-links">
                <Button
                  className="lets-navigate active"
                  htmlType="submit"
                  loading={loading}
                >
                  Software skills
                </Button>
              </Link>
            </div>
            <MainHeading>Software skills</MainHeading>

            <div className="form-holder">
              <Form
                form={form}
                layout="vertical"
                className="FormWrapper"
                onFinish={onFinish}
              >
                <Form.Item name="software">
                  <SecondaryHeading style={{ marginBottom: "0px" }}>
                    Select the software you use
                  </SecondaryHeading>
                  <p className="grading-text">
                    0-No Experience
                    <span>1-Basic Understanding</span>
                    <span>2-Competent</span>
                    <span>
                      <br />
                      3-Good
                    </span>
                    <span>4-Expert</span>
                  </p>
                  {softwares.map((item, IndexKind) => {
                    return (
                      <div className="softwaresDiv">
                        <Checkbox
                          checked={item.isSelected}
                          value={item.value}
                          onChange={(e) => onChangeSoftware(e, IndexKind)}
                        >
                          {item.label}
                        </Checkbox>
                        {item.isSelected && (
                          <Radio.Group
                            onChange={(e) => onChangeGrading(e, IndexKind)}
                            value={item.grading}
                          >
                            <Radio value={0}>0</Radio>
                            <Radio value={1}>1</Radio>
                            <Radio value={2}>2</Radio>
                            <Radio value={3}>3</Radio>
                            <Radio value={4}>4</Radio>
                          </Radio.Group>
                        )}
                      </div>
                    );
                  })}
                </Form.Item>

                <Form.Item name={"ir35"}>
                  <SecondaryHeading>IR35 Preference</SecondaryHeading>
                  <div>
                    <Radio.Group onChange={onChangeIR35} value={ir35}>
                      <Radio value={"Inside Only"}>
                        <RadioButtonTitle
                          style={
                            ir35 === "Inside Only"
                              ? { fontWeight: "600", color: "#393939" }
                              : {}
                          }
                        >
                          Inside Only
                        </RadioButtonTitle>
                      </Radio>
                      <Radio value={"Outside Only"}>
                        <RadioButtonTitle
                          style={
                            ir35 === "Outside Only"
                              ? { fontWeight: "600", color: "#393939" }
                              : {}
                          }
                        >
                          Outside Only
                        </RadioButtonTitle>
                      </Radio>
                      <Radio value={"No Preferences"}>
                        <RadioButtonTitle
                          style={
                            ir35 === "No Preferences"
                              ? { fontWeight: "600", color: "#393939" }
                              : {}
                          }
                        >
                          No Preferences
                        </RadioButtonTitle>
                      </Radio>
                    </Radio.Group>
                  </div>
                </Form.Item>

                <Form.Item name="availability">
                  <SecondaryHeading>Availability</SecondaryHeading>
                  <div>
                    <Radio.Group
                      onChange={onChangeAvailability}
                      value={availability}
                    >
                      <Radio value={"Available now"}>
                        <RadioButtonTitle
                          style={
                            availability === "Available now"
                              ? { fontWeight: "600", color: "#393939" }
                              : {}
                          }
                        >
                          Available now
                        </RadioButtonTitle>
                      </Radio>
                      <Radio value={"date"}>
                        <RadioButtonTitle
                          style={
                            availability === "date"
                              ? { fontWeight: "600", color: "#393939" }
                              : {}
                          }
                        >
                          Select specific date
                        </RadioButtonTitle>
                      </Radio>
                    </Radio.Group>
                  </div>
                </Form.Item>
                {availability === "date" && (
                  <Form.Item name={"date"}>
                    <DatePicker
                      placeholder="dd/mm/yy"
                      size={"large"}
                      onSelect={(val) => setDate(val)}
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        border: "1px solid #D9D9D9",
                        height: "50px",
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    />
                  </Form.Item>
                )}

                <div className="main-btn">
                  <Button
                    className="lets-go"
                    htmlType="submit"
                    loading={loading}
                  >
                    Finish & submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </WellComeBoxActaurial>
      </DashBoardWrapper>
    </SoftSkillOuterWrapper>
  );
};

export default Home;
