import { Button } from "antd";
import {
  WellComeBoxActaurial,
  DashBoardWrapper,
  MainHeading,
  SubHeading,
} from "./styles";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <DashBoardWrapper>
      <WellComeBoxActaurial>
        <div>
          <MainHeading>Welcome to Box Actaurial</MainHeading>
          <SubHeading>
            We need more information about you to add to our database. It will
            be helpful for other people to find and interact with you.
          </SubHeading>
          <div className="main-btn">
            <Button
              className="lets-go"
              onClick={() => navigate("/portal/personal-information")}
            >
              Let's go
            </Button>
          </div>
        </div>
      </WellComeBoxActaurial>
    </DashBoardWrapper>
  );
};

export default Home;
