import styled from "styled-components";
import hero_img from "../../Assets/Images/hero.png";

export const NavbarWrapper = styled.div`
  .bg-nav {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .nav-links {
    color: white;
    margin-left: 1.4rem;
  }
  .navbar-nav .nav-link.active,
  .navbar-nav .show > .nav-link {
    color: white !important;
  }
  .btn-login {
    background-color: white;
    padding: 6px 20px;
    border-radius: 4px;
    color: black;
    border: 2px solid white;
    font-size: 0.9em;
  }
  .navbar-toggler {
    background-color: white;
  }
  @media (max-width: 768px) {
    .nav-links {
      margin-left: 0rem;
    }
    .btn-login {
      margin-top: 0.7rem;
    }
  }
`;

export const HomeWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT:wght@400;700&display=swap");
  h1,
  h2,
  h3,
  p {
    font-family: "Old Standard TT", serif;
  }

  .hero-section {
    background: url(${hero_img}) no-repeat center;
    background-size: cover;
    height: 100vh;
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    z-index: -1;
  }
  .hero-heading {
    font-size: 3rem;
    padding-top: 10rem;
    font-weight: bold;
  }
  .sub-heading {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .card-heading {
    text-align: center;
    font-size: 1.2rem;
  }
  .cta-button {
    background-color: transparent;
    padding: 10px 40px;
    border-radius: 4px;
    color: white;
    border: 2px solid white;
    margin-top: 2rem;
    font-size: 0.9em;
  }
  .about-card {
    border-radius: 0px;
    border-left: 0px;
    border-right: 1px solid #dee2e6;
    border-top: 0px;
    border-bottom: 0px;
  }
  .footer {
    background-color: #393939;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
    margin-top: 6rem;
  }
  .footer-link {
    display: flex;
    gap: 10px;
    justify-content: end;
  }
  .help-container .small-text {
    text-align: right;
    font-weight: bold;
    font-size: 1rem;
  }
  .help-container .card {
    border: none;
  }
  @media (max-width: 768px) {
    .hero-section {
      height: auto;
    }
    .overlay {
      height: auto;
    }
    .hero-heading {
      padding-top: 4rem;
    }
    .footer-link {
      justify-content: center;
    }
    .about-card {
      border-right: 0px;
    }
  }
`;
