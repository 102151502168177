import { AppRoutes } from "constants/routes";
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../Assets/Logos/logo_white.svg";
import { NavbarWrapper } from "./styles";
import { HashLink } from "react-router-hash-link";

const NavBarLanding = ({ bg }: any) => {
  const isAuthenticated = localStorage.getItem("token");

  return (
    <NavbarWrapper>
      <Navbar
        // className="bg-nav"
        style={{ backgroundColor: `${bg}`, zIndex: "1" }}
        expand="lg"
        fixed="top"
      >
        <Container>
          <Navbar.Brand href="/">
            <Image src={logo} fluid width={130} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-start align-items-md-center">
              <HashLink to="/#home" className="nav-links">
                Home
              </HashLink>
              <HashLink to="/#service" className="nav-links">
                Services
              </HashLink>
              <Link to={AppRoutes.contact} className="nav-links">
                Contact us
              </Link>
              {isAuthenticated ? (
                <Link to="/portal" className="nav-links">
                  <Button className="btn-login">Dashboard</Button>
                </Link>
              ) : (
                <Link to="/portal/login" className="nav-links">
                  <Button className="btn-login">Login</Button>
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </NavbarWrapper>
  );
};

export default NavBarLanding;
