import { Button, Form, Input, Radio, Select } from "antd";
import { useAppDispatch } from "Hooks/Store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loading as stateLoading,
  user,
  role,
  visibility,
  displayName,
} from "Redux/App";
import { PersonalInformationAction } from "Redux/App/Actions/Items";
import {
  WellComeBoxActaurial,
  DashBoardWrapper,
  MainHeading,
  InputsHeading,
} from "../styles";
import { GetItemsAction } from "Redux/App/Actions/Items";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch, Space } from "antd";
import { updateUser } from "Redux/App/Actions/Auth";
import { Link } from "react-router-dom";
const Home = () => {
  const loading = useSelector(stateLoading);
  const userData = useSelector(user);
  const userVisibility = useSelector(visibility);
  const Name: any = useSelector(displayName);
  const Role: any = useSelector(role);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };
  const [name, setName] = useState(Name);
  const [locations, setLocations] = useState<
    {
      label: string;
      value: string;
    }[]
  >([
    {
      label: "United Kingdom",
      value: "United Kingdom",
    },
    {
      label: "Afghanistan",
      value: "Afghanistan",
    },
    {
      label: "Albania",
      value: "Albania",
    },
    {
      label: "Algeria",
      value: "Algeria",
    },
    {
      label: "American Samoa",
      value: "American Samoa",
    },
    {
      label: "Andorra",
      value: "Andorra",
    },
    {
      label: "Angola",
      value: "Angola",
    },
    {
      label: "Anguilla",
      value: "Anguilla",
    },
    {
      label: "Antarctica",
      value: "Antarctica",
    },
    {
      label: "Antigua and Barbuda",
      value: "Antigua and Barbuda",
    },
    {
      label: "Argentina",
      value: "Argentina",
    },
    {
      label: "Armenia",
      value: "Armenia",
    },
    {
      label: "Aruba",
      value: "Aruba",
    },
    {
      label: "Australia",
      value: "Australia",
    },
    {
      label: "Austria",
      value: "Austria",
    },
    {
      label: "Azerbaijan",
      value: "Azerbaijan",
    },
    {
      label: "Bahamas",
      value: "Bahamas",
    },
    {
      label: "Bahrain",
      value: "Bahrain",
    },
    {
      label: "Bangladesh",
      value: "Bangladesh",
    },
    {
      label: "Barbados",
      value: "Barbados",
    },
    {
      label: "Belarus",
      value: "Belarus",
    },
    {
      label: "Belgium",
      value: "Belgium",
    },
    {
      label: "Belize",
      value: "Belize",
    },
    {
      label: "Benin",
      value: "Benin",
    },
    {
      label: "Bermuda",
      value: "Bermuda",
    },
    {
      label: "Bhutan",
      value: "Bhutan",
    },
    {
      label: "Bolivia",
      value: "Bolivia",
    },
    {
      label: "Bosnia and Herzegovina",
      value: "Bosnia and Herzegovina",
    },
    {
      label: "Botswana",
      value: "Botswana",
    },
    {
      label: "Bouvet Island",
      value: "Bouvet Island",
    },
    {
      label: "Brazil",
      value: "Brazil",
    },
    {
      label: "British Indian Ocean Territory",
      value: "British Indian Ocean Territory",
    },
    {
      label: "British Virgin Islands",
      value: "British Virgin Islands",
    },
    {
      label: "Brunei",
      value: "Brunei",
    },
    {
      label: "Bulgaria",
      value: "Bulgaria",
    },
    {
      label: "Burkina Faso",
      value: "Burkina Faso",
    },
    {
      label: "Burundi",
      value: "Burundi",
    },
    {
      label: "Cambodia",
      value: "Cambodia",
    },
    {
      label: "Cameroon",
      value: "Cameroon",
    },
    {
      label: "Canada",
      value: "Canada",
    },
    {
      label: "Cape Verde",
      value: "Cape Verde",
    },
    {
      label: "Caribbean Netherlands",
      value: "Caribbean Netherlands",
    },
    {
      label: "Cayman Islands",
      value: "Cayman Islands",
    },
    {
      label: "Central African Republic",
      value: "Central African Republic",
    },
    {
      label: "Chad",
      value: "Chad",
    },
    {
      label: "Chile",
      value: "Chile",
    },
    {
      label: "China",
      value: "China",
    },
    {
      label: "Christmas Island",
      value: "Christmas Island",
    },
    {
      label: "Cocos (Keeling) Islands",
      value: "Cocos (Keeling) Islands",
    },
    {
      label: "Colombia",
      value: "Colombia",
    },
    {
      label: "Comoros",
      value: "Comoros",
    },
    {
      label: "Cook Islands",
      value: "Cook Islands",
    },
    {
      label: "Costa Rica",
      value: "Costa Rica",
    },
    {
      label: "Croatia",
      value: "Croatia",
    },
    {
      label: "Cuba",
      value: "Cuba",
    },
    {
      label: "Curaçao",
      value: "Curaçao",
    },
    {
      label: "Cyprus",
      value: "Cyprus",
    },
    {
      label: "Czechia",
      value: "Czechia",
    },
    {
      label: "DR Congo",
      value: "DR Congo",
    },
    {
      label: "Denmark",
      value: "Denmark",
    },
    {
      label: "Djibouti",
      value: "Djibouti",
    },
    {
      label: "Dominica",
      value: "Dominica",
    },
    {
      label: "Dominican Republic",
      value: "Dominican Republic",
    },
    {
      label: "Ecuador",
      value: "Ecuador",
    },
    {
      label: "Egypt",
      value: "Egypt",
    },
    {
      label: "El Salvador",
      value: "El Salvador",
    },
    {
      label: "Equatorial Guinea",
      value: "Equatorial Guinea",
    },
    {
      label: "Eritrea",
      value: "Eritrea",
    },
    {
      label: "Estonia",
      value: "Estonia",
    },
    {
      label: "Eswatini",
      value: "Eswatini",
    },
    {
      label: "Ethiopia",
      value: "Ethiopia",
    },
    {
      label: "Falkland Islands",
      value: "Falkland Islands",
    },
    {
      label: "Faroe Islands",
      value: "Faroe Islands",
    },
    {
      label: "Fiji",
      value: "Fiji",
    },
    {
      label: "Finland",
      value: "Finland",
    },
    {
      label: "France",
      value: "France",
    },
    {
      label: "French Guiana",
      value: "French Guiana",
    },
    {
      label: "French Polynesia",
      value: "French Polynesia",
    },
    {
      label: "French Southern and Antarctic Lands",
      value: "French Southern and Antarctic Lands",
    },
    {
      label: "Gabon",
      value: "Gabon",
    },
    {
      label: "Gambia",
      value: "Gambia",
    },
    {
      label: "Georgia",
      value: "Georgia",
    },
    {
      label: "Germany",
      value: "Germany",
    },
    {
      label: "Ghana",
      value: "Ghana",
    },
    {
      label: "Gibraltar",
      value: "Gibraltar",
    },
    {
      label: "Greece",
      value: "Greece",
    },
    {
      label: "Greenland",
      value: "Greenland",
    },
    {
      label: "Grenada",
      value: "Grenada",
    },
    {
      label: "Guadeloupe",
      value: "Guadeloupe",
    },
    {
      label: "Guam",
      value: "Guam",
    },
    {
      label: "Guatemala",
      value: "Guatemala",
    },
    {
      label: "Guernsey",
      value: "Guernsey",
    },
    {
      label: "Guinea",
      value: "Guinea",
    },
    {
      label: "Guinea-Bissau",
      value: "Guinea-Bissau",
    },
    {
      label: "Guyana",
      value: "Guyana",
    },
    {
      label: "Haiti",
      value: "Haiti",
    },
    {
      label: "Heard Island and McDonald Islands",
      value: "Heard Island and McDonald Islands",
    },
    {
      label: "Honduras",
      value: "Honduras",
    },
    {
      label: "Hong Kong",
      value: "Hong Kong",
    },
    {
      label: "Hungary",
      value: "Hungary",
    },
    {
      label: "Iceland",
      value: "Iceland",
    },
    {
      label: "India",
      value: "India",
    },
    {
      label: "Indonesia",
      value: "Indonesia",
    },
    {
      label: "Iran",
      value: "Iran",
    },
    {
      label: "Iraq",
      value: "Iraq",
    },
    {
      label: "Ireland",
      value: "Ireland",
    },
    {
      label: "Isle of Man",
      value: "Isle of Man",
    },
    {
      label: "Israel",
      value: "Israel",
    },
    {
      label: "Italy",
      value: "Italy",
    },
    {
      label: "Ivory Coast",
      value: "Ivory Coast",
    },
    {
      label: "Jamaica",
      value: "Jamaica",
    },
    {
      label: "Japan",
      value: "Japan",
    },
    {
      label: "Jersey",
      value: "Jersey",
    },
    {
      label: "Jordan",
      value: "Jordan",
    },
    {
      label: "Kazakhstan",
      value: "Kazakhstan",
    },
    {
      label: "Kenya",
      value: "Kenya",
    },
    {
      label: "Kiribati",
      value: "Kiribati",
    },
    {
      label: "Kosovo",
      value: "Kosovo",
    },
    {
      label: "Kuwait",
      value: "Kuwait",
    },
    {
      label: "Kyrgyzstan",
      value: "Kyrgyzstan",
    },
    {
      label: "Laos",
      value: "Laos",
    },
    {
      label: "Latvia",
      value: "Latvia",
    },
    {
      label: "Lebanon",
      value: "Lebanon",
    },
    {
      label: "Lesotho",
      value: "Lesotho",
    },
    {
      label: "Liberia",
      value: "Liberia",
    },
    {
      label: "Libya",
      value: "Libya",
    },
    {
      label: "Liechtenstein",
      value: "Liechtenstein",
    },
    {
      label: "Lithuania",
      value: "Lithuania",
    },
    {
      label: "Luxembourg",
      value: "Luxembourg",
    },
    {
      label: "Macau",
      value: "Macau",
    },
    {
      label: "Madagascar",
      value: "Madagascar",
    },
    {
      label: "Malawi",
      value: "Malawi",
    },
    {
      label: "Malaysia",
      value: "Malaysia",
    },
    {
      label: "Maldives",
      value: "Maldives",
    },
    {
      label: "Mali",
      value: "Mali",
    },
    {
      label: "Malta",
      value: "Malta",
    },
    {
      label: "Marshall Islands",
      value: "Marshall Islands",
    },
    {
      label: "Martinique",
      value: "Martinique",
    },
    {
      label: "Mauritania",
      value: "Mauritania",
    },
    {
      label: "Mauritius",
      value: "Mauritius",
    },
    {
      label: "Mayotte",
      value: "Mayotte",
    },
    {
      label: "Mexico",
      value: "Mexico",
    },
    {
      label: "Micronesia",
      value: "Micronesia",
    },
    {
      label: "Moldova",
      value: "Moldova",
    },
    {
      label: "Monaco",
      value: "Monaco",
    },
    {
      label: "Mongolia",
      value: "Mongolia",
    },
    {
      label: "Montenegro",
      value: "Montenegro",
    },
    {
      label: "Montserrat",
      value: "Montserrat",
    },
    {
      label: "Morocco",
      value: "Morocco",
    },
    {
      label: "Mozambique",
      value: "Mozambique",
    },
    {
      label: "Myanmar",
      value: "Myanmar",
    },
    {
      label: "Namibia",
      value: "Namibia",
    },
    {
      label: "Nauru",
      value: "Nauru",
    },
    {
      label: "Nepal",
      value: "Nepal",
    },
    {
      label: "Netherlands",
      value: "Netherlands",
    },
    {
      label: "New Caledonia",
      value: "New Caledonia",
    },
    {
      label: "New Zealand",
      value: "New Zealand",
    },
    {
      label: "Nicaragua",
      value: "Nicaragua",
    },
    {
      label: "Niger",
      value: "Niger",
    },
    {
      label: "Nigeria",
      value: "Nigeria",
    },
    {
      label: "Niue",
      value: "Niue",
    },
    {
      label: "Norfolk Island",
      value: "Norfolk Island",
    },
    {
      label: "North Korea",
      value: "North Korea",
    },
    {
      label: "North Macedonia",
      value: "North Macedonia",
    },
    {
      label: "Northern Mariana Islands",
      value: "Northern Mariana Islands",
    },
    {
      label: "Norway",
      value: "Norway",
    },
    {
      label: "Oman",
      value: "Oman",
    },
    {
      label: "Pakistan",
      value: "Pakistan",
    },
    {
      label: "Palau",
      value: "Palau",
    },
    {
      label: "Palestine",
      value: "Palestine",
    },
    {
      label: "Panama",
      value: "Panama",
    },
    {
      label: "Papua New Guinea",
      value: "Papua New Guinea",
    },
    {
      label: "Paraguay",
      value: "Paraguay",
    },
    {
      label: "Peru",
      value: "Peru",
    },
    {
      label: "Philippines",
      value: "Philippines",
    },
    {
      label: "Pitcairn Islands",
      value: "Pitcairn Islands",
    },
    {
      label: "Poland",
      value: "Poland",
    },
    {
      label: "Portugal",
      value: "Portugal",
    },
    {
      label: "Puerto Rico",
      value: "Puerto Rico",
    },
    {
      label: "Qatar",
      value: "Qatar",
    },
    {
      label: "Republic of the Congo",
      value: "Republic of the Congo",
    },
    {
      label: "Romania",
      value: "Romania",
    },
    {
      label: "Russia",
      value: "Russia",
    },
    {
      label: "Rwanda",
      value: "Rwanda",
    },
    {
      label: "Réunion",
      value: "Réunion",
    },
    {
      label: "Saint Barthélemy",
      value: "Saint Barthélemy",
    },
    {
      label: "Saint Helena, Ascension and Tristan da Cunha",
      value: "Saint Helena, Ascension and Tristan da Cunha",
    },
    {
      label: "Saint Kitts and Nevis",
      value: "Saint Kitts and Nevis",
    },
    {
      label: "Saint Lucia",
      value: "Saint Lucia",
    },
    {
      label: "Saint Martin",
      value: "Saint Martin",
    },
    {
      label: "Saint Pierre and Miquelon",
      value: "Saint Pierre and Miquelon",
    },
    {
      label: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    {
      label: "Samoa",
      value: "Samoa",
    },
    {
      label: "San Marino",
      value: "San Marino",
    },
    {
      label: "Saudi Arabia",
      value: "Saudi Arabia",
    },
    {
      label: "Senegal",
      value: "Senegal",
    },
    {
      label: "Serbia",
      value: "Serbia",
    },
    {
      label: "Seychelles",
      value: "Seychelles",
    },
    {
      label: "Sierra Leone",
      value: "Sierra Leone",
    },
    {
      label: "Singapore",
      value: "Singapore",
    },
    {
      label: "Sint Maarten",
      value: "Sint Maarten",
    },
    {
      label: "Slovakia",
      value: "Slovakia",
    },
    {
      label: "Slovenia",
      value: "Slovenia",
    },
    {
      label: "Solomon Islands",
      value: "Solomon Islands",
    },
    {
      label: "Somalia",
      value: "Somalia",
    },
    {
      label: "South Africa",
      value: "South Africa",
    },
    {
      label: "South Georgia",
      value: "South Georgia",
    },
    {
      label: "South Korea",
      value: "South Korea",
    },
    {
      label: "South Sudan",
      value: "South Sudan",
    },
    {
      label: "Spain",
      value: "Spain",
    },
    {
      label: "Sri Lanka",
      value: "Sri Lanka",
    },
    {
      label: "Sudan",
      value: "Sudan",
    },
    {
      label: "Suriname",
      value: "Suriname",
    },
    {
      label: "Svalbard and Jan Mayen",
      value: "Svalbard and Jan Mayen",
    },
    {
      label: "Sweden",
      value: "Sweden",
    },
    {
      label: "Switzerland",
      value: "Switzerland",
    },
    {
      label: "Syria",
      value: "Syria",
    },
    {
      label: "São Tomé and Príncipe",
      value: "São Tomé and Príncipe",
    },
    {
      label: "Taiwan",
      value: "Taiwan",
    },
    {
      label: "Tajikistan",
      value: "Tajikistan",
    },
    {
      label: "Tanzania",
      value: "Tanzania",
    },
    {
      label: "Thailand",
      value: "Thailand",
    },
    {
      label: "Timor-Leste",
      value: "Timor-Leste",
    },
    {
      label: "Togo",
      value: "Togo",
    },
    {
      label: "Tokelau",
      value: "Tokelau",
    },
    {
      label: "Tonga",
      value: "Tonga",
    },
    {
      label: "Trinidad and Tobago",
      value: "Trinidad and Tobago",
    },
    {
      label: "Tunisia",
      value: "Tunisia",
    },
    {
      label: "Turkey",
      value: "Turkey",
    },
    {
      label: "Turkmenistan",
      value: "Turkmenistan",
    },
    {
      label: "Turks and Caicos Islands",
      value: "Turks and Caicos Islands",
    },
    {
      label: "Tuvalu",
      value: "Tuvalu",
    },
    {
      label: "Uganda",
      value: "Uganda",
    },
    {
      label: "Ukraine",
      value: "Ukraine",
    },
    {
      label: "United Arab Emirates",
      value: "United Arab Emirates",
    },
    {
      label: "United States",
      value: "United States",
    },
    {
      label: "United States Minor Outlying Islands",
      value: "United States Minor Outlying Islands",
    },
    {
      label: "United States Virgin Islands",
      value: "United States Virgin Islands",
    },
    {
      label: "Uruguay",
      value: "Uruguay",
    },
    {
      label: "Uzbekistan",
      value: "Uzbekistan",
    },
    {
      label: "Vanuatu",
      value: "Vanuatu",
    },
    {
      label: "Vatican City",
      value: "Vatican City",
    },
    {
      label: "Venezuela",
      value: "Venezuela",
    },
    {
      label: "Vietnam",
      value: "Vietnam",
    },
    {
      label: "Wallis and Futuna",
      value: "Wallis and Futuna",
    },
    {
      label: "Western Sahara",
      value: "Western Sahara",
    },
    {
      label: "Yemen",
      value: "Yemen",
    },
    {
      label: "Zambia",
      value: "Zambia",
    },
    {
      label: "Zimbabwe",
      value: "Zimbabwe",
    },
    {
      label: "Åland Islands",
      value: "Åland Islands",
    },
  ]);
  const [selectedActivityRole, setSelectedActivityRole] = useState(Role);

  const onActivityRoleChange = (e: any) => {
    const newValue = e.target.value;
    setSelectedActivityRole(newValue);
  };

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        email: userData["email"],
        dispayName: userData["displayName"],
      });
    }
    if (["baseLocation", "workingLocation"].every((key) => key in userData)) {
      form.setFieldsValue({
        baseLocation: userData["baseLocation"],
        workingLocation: userData["workingLocation"],
      });
    }
  }, []);
  useEffect(() => {
    dispatch<any>(GetItemsAction(0, navigate));
  }, []);
  const [state, setState] = useState(userVisibility);
  const onFinish = (values: any) => {
    values["userID"] = userData?.userID;
    values["visibility"] = !userData?.visibility;
    values["activityRole"] = selectedActivityRole;

    dispatch<any>(PersonalInformationAction(values, navigate));
    dispatch<any>(
      updateUser(
        userData?.userID,
        name
          ? {
              displayName: name,
              visibility: state,
              activityRole: selectedActivityRole,
            }
          : {},
        false
      )
    );
  };
  return (
    <DashBoardWrapper>
      <WellComeBoxActaurial>
        <div>
          <div>
            <div className="main-btn">
              <Link to="/portal/personal-information" className="nav-links">
                <Button
                  className="lets-navigate active "
                  htmlType="submit"
                  loading={loading}
                >
                  Personal Information
                </Button>
              </Link>
              <Link to="/portal/expertise" className="nav-links">
                <Button
                  className="lets-navigate "
                  htmlType="submit"
                  loading={loading}
                >
                  Expertise
                </Button>
              </Link>
              <Link to="/portal/soft-skill" className="nav-links">
                <Button
                  className="lets-navigate "
                  htmlType="submit"
                  loading={loading}
                >
                  Software skills
                </Button>
              </Link>
            </div>
            <MainHeading>Personal Information</MainHeading>

            <div className="form-holder">
              <Form
                form={form}
                layout="vertical"
                className="FormWrapper"
                onFinish={onFinish}
                style={{ width: "60%" }}
              >
                <Form.Item name="email">
                  <Input placeholder="Email" disabled />
                </Form.Item>
                <InputsHeading>Display Name</InputsHeading>
                {/* <Form.Item name="dispayName"> */}
                <Input
                  className=""
                  name="dispayName"
                  defaultValue={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="dispayNmae"
                />
                <p
                  style={{
                    fontSize: "12px",
                    marginTop: "5px",
                    marginBottom: "30px",
                    display: "flex",
                  }}
                >
                  Note that this name is what will be displayed to users of the
                  website and will be used by recruiters to find you
                </p>
                {/* </Form.Item> */}
                <InputsHeading>What location are you based in?</InputsHeading>
                <Form.Item
                  name="baseLocation"
                  rules={[
                    {
                      required: true,
                      message: "Please input your base location!",
                    },
                  ]}
                >
                  <Select
                    options={locations}
                    placeholder="e.g. USA"
                    mode="multiple"
                  ></Select>
                </Form.Item>
                <InputsHeading>
                  What Locations would you consider working in?
                </InputsHeading>
                <Form.Item
                  name="workingLocation"
                  rules={[
                    {
                      required: true,
                      message: "Please input your working location!",
                    },
                  ]}
                >
                  <Select
                    options={locations}
                    placeholder="e.g. INDIA"
                    mode="multiple"
                  ></Select>
                </Form.Item>
                <Form.Item name="activityRole" label="Role">
                  <Radio.Group
                    className=""
                    onChange={onActivityRoleChange}
                    value={selectedActivityRole}
                    defaultValue={selectedActivityRole}
                  >
                    <Radio value="Recruiter">Recruiter</Radio>
                    <Radio value="Employer">Employer</Radio>
                    <Radio value="Contractor">Contractor</Radio>
                  </Radio.Group>
                </Form.Item>
                <InputsHeading>Profile Visibility </InputsHeading>{" "}
                <Space direction="vertical">
                  <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    checked={state}
                    onChange={(checked) => {
                      setState(!state);
                    }}
                  />
                </Space>
                <Form.Item>
                  <div className="main-btn">
                    <Button
                      className="lets-go"
                      htmlType="submit"
                      loading={loading}
                    >
                      Next
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </WellComeBoxActaurial>
    </DashBoardWrapper>
  );
};

export default Home;
