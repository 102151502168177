import { Button, Col, Form, Input, Row } from "antd";
import BoxActaurialAuthIcon from "Assets/Logos/BoxActaurialAuthIcon";
import { AppRoutes } from "constants/routes";
import { useAppDispatch } from "Hooks/Store";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ForgetPasswordAction } from "Redux/App/Actions/Auth";
import { FormWrapper, LoginCard, LoginWrapper } from "./styles";
import { loading as stateLoading } from "Redux/App";
import { useSelector } from "react-redux";

const ForgotPassword = () => {
  const loading = useSelector(stateLoading);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    dispatch<any>(ForgetPasswordAction(values, navigate));
  };

  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center", marginBottom: "22px" }}>
              <BoxActaurialAuthIcon />
            </div>
          </Col>
        </Row>

        <h1 className="form-primary-heading">Forgot Password</h1>
        <p className="form-text ">Enter your email address</p>

        <FormWrapper>
          <Form
            form={form}
            layout="vertical"
            className="FormWrapper"
            onFinish={onFinish}
            style={{ position: "relative" }}
          >
            {loading ? <div className="overlay"></div> : ""}
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please enter your email" }]}
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  className="form-submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
            <p>
              <span style={{ color: "#BBBBBB" }}>Remember password?&nbsp;</span>
              <Link
                to={AppRoutes.login}
                style={{ color: "#393939", textDecoration: "underline" }}
              >
                Login
              </Link>
            </p>
          </Form>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default ForgotPassword;
