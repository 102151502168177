import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";

interface initialStateTypes {
  role: String,
  visibility: boolean,
  displayName: String,
  isAuthorized: boolean;
  isRecordEntered: boolean;
  isReload: boolean;
  loading: boolean;
  resetLoading: boolean;
  hasErrors: boolean;
  userData: any;
  profiles: {
    data: [],
    total: number,
  };
  locations: Array<string>;
  stepCount: number;
}

// export interface finalStateTypes {
//   app: {
//     isAuthorized: boolean;
//     loading: boolean;
//     hasErrors: boolean;
//     userData: {
//       _id: string;
//       name: string;
//       email: string;
//       password: string;
//       otp: string;
//       isVerified: string;
//       role: string;
//     };
//     data: [];
//     stepCount: number;
//   };
// }

const initialState: initialStateTypes = {
  role: '',
  displayName: '',
  visibility: false,
  isAuthorized: false,
  isRecordEntered: false,
  isReload: false,
  loading: false,
  resetLoading: false,
  hasErrors: false,
  userData: {},
  profiles: {
    data: [],
    total: 0,
  },
  locations: [],
  stepCount: 0,
};

const appReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    requestStart: (state) => {
      state.loading = true;
    },
    resetRequestStart: (state) => {
      state.resetLoading = true;
    },
    requestCompleted: (state) => {
      state.loading = false;
    },
    resetRequestCompleted: (state) => {
      state.resetLoading = false;
    },

    requestSuccess(state, action: PayloadAction<any>) {
      state.profiles = action.payload;
      state.loading = false;
      state.resetLoading = false;
      state.hasErrors = false;
    },

    requestFailure(state) {
      state.loading = false;
      state.hasErrors = true;
    },

    resetData(state) {
      state.profiles = {
        data: [],
        total: 0,
      };
    },

    userLoggedIn(state, action: PayloadAction<{ userData: { visibility: any, displayName: any, activityRole: any }, isRecordEntered: boolean }>) {
      console.log(action.payload.userData, "action.payload.userData MMMMMMMMMMMMM")
      state.isAuthorized = true;
      state.isRecordEntered = action.payload.isRecordEntered;
      state.userData = action.payload.userData;
      state.visibility = action.payload.userData.visibility
      state.displayName = action.payload.userData.displayName
      state.role = action.payload.userData.activityRole
    },

    userLoggedOut(state) {
      state.isAuthorized = false;
      state.isRecordEntered = false;
      state.userData = {};
      state.profiles = {
        data: [],
        total: 0,
      };
      state.stepCount = 0;
    },

    setStepCount(state, action: PayloadAction<number>) {
      state.stepCount = action.payload;
    },

    setLocations(state, action: PayloadAction<any>) {
      state.locations = action.payload;
    },

    setIsRecordEntered(state, action: PayloadAction<string>) {
      const index = state.profiles.data.findIndex((item: any) => item.userID === action.payload);
      if (index !== -1) {
        state.isRecordEntered = true;
      }
      state.loading = false;
    },

    setPersonalInformation(state, action: PayloadAction<any>) {
      state.userData["baseLocation"] = action.payload.baseLocation;
      state.userData["workingLocation"] = action.payload.workingLocation;
      state.stepCount = 1;
      state.loading = false;
    },
    setExpertise(state, action: PayloadAction<any>) {
      state.userData["areaOfExpertise"] = action.payload.areaOfExpertise;
      state.userData["areaOfSpeciality"] = action.payload.areaOfSpeciality;
      state.userData["qualification"] = action.payload.qualification;
      state.userData["pqe"] = action.payload.pqe;
      state.userData["totalYears"] = action.payload.totalYears;
      state.stepCount = 2;
      state.loading = false;
    },
    setSoftwareSkills(state, action: PayloadAction<any>) {
      state.isRecordEntered = action.payload.isRecordEntered;
      state.userData["softwares"] = action.payload.softwares;
      state.userData["ir35"] = action.payload.ir35;
      state.userData["availability"] = action.payload.availability;
      state.stepCount = 0;
      state.loading = false;
    },
    setIsReload: (state, action: PayloadAction<boolean>) => {
      state.isReload = action.payload;
    },
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.visibility = action.payload;
    },
    setDisplayName: (state, action: PayloadAction<String>) => {
      state.displayName = action.payload;
    },
    setRole: (state, action: PayloadAction<String>) => {
      state.role = action.payload;
    },
  },
});

export const data = (state: RootState) => state.profiles;
export const loading = (state: RootState) => state.loading;
export const resetLoading = (state: RootState) => state.resetLoading;
export const user = (state: RootState) => state.userData;
export const stepCount = (state: RootState) => state.stepCount;
export const IsRecordEntered = (state: RootState) => state.isRecordEntered;
export const locations = (state: RootState) => state.locations;
export const isReload = (state: RootState) => state.isReload;
export const visibility = (state: RootState) => state.visibility;
export const displayName = (state: RootState) => state.displayName;
export const role = (state: RootState) => state.role;

export const {
  requestStart,
  resetRequestStart,
  requestSuccess,
  requestFailure,
  requestCompleted,
  resetRequestCompleted,
  resetData,
  userLoggedIn,
  setRole,
  setVisibility,
  setDisplayName,
  userLoggedOut,
  setStepCount,
  setIsRecordEntered,
  setLocations,
  setPersonalInformation,
  setExpertise,
  setSoftwareSkills,
  setIsReload,
} = appReducer.actions;

export default appReducer.reducer;
