import styled from "styled-components";

export const FileUploadWrapper = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 150px;
  }
  .tc-file-upload {
    display: flex;
    gap: 15px;
    align-items: center;
    p {
      margin-bottom: 0px;
    }
  }
  .file-name {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0px !important;
  }
  .file-date {
    color: #98999a;
    font-size: 11px;
    margin-bottom: 0px;
  }
`;
