import { Button, Col, Form, Input, Row } from "antd";
import BoxActaurialAuthIcon from "Assets/Logos/BoxActaurialAuthIcon";
import { AppRoutes } from "constants/routes";
import { Link } from "react-router-dom";
import { FormWrapper, LoginCard, LoginWrapper } from "./styles";

const ResetLinkSuccess = () => {
  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center", marginBottom: "22px" }}>
              <BoxActaurialAuthIcon />
            </div>
          </Col>
        </Row>

        <h1 className="form-primary-heading"> Success!</h1>
        <p className="form-text ">
          Password reset link has been sent to your email address
          <br />
          Please check your email
        </p>
      </LoginCard>
    </LoginWrapper>
  );
};

export default ResetLinkSuccess;
