import { AppRoutes } from "constants/routes";
import NavBarLanding from "Pages/HomeSlash/NavBarLanding";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { TermsWrapper } from "./styles";
import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { GetTermsAndCondition } from "Redux/App/Actions/Auth";
import { data } from "Redux/App";
import { useAppDispatch } from "Hooks/Store";
const TermsConditions = () => {
  const [arr, setArr] = useState<any>(null);
  const termsAndCond = useSelector(data);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch<any>(GetTermsAndCondition());
    getData()
  }, [1000])
  const getData = () => {
    console.log(termsAndCond.data, "kkk")
    setArr(termsAndCond.data)
  }
  return (
    <TermsWrapper>
      <NavBarLanding bg="rgb(57,57,57)" />
      <Container className="mt-5 pt-5">
        <Row className="justify-content-center">
          <Col md={8}>
            <h1 className="text-center">
              Terms and Conditions of Use of the Box Actuarial Limited Website
              and the Actuarial <br /> Contractors’ Database (Version 1.1)
              <br /> 10 March 2023
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>1. INTRODUCTION</h2>
            <p>
              These Website Standard Terms And Conditions (these “Terms” or
              these “Website Standard Terms And Conditions”) contained herein on
              this webpage, shall govern your use of this website, including all
              pages and the Actuarial Contractors’ Database (“ACDB” or
              “Database”) elements within this website (collectively referred to
              herein below as this “Website”). These Terms apply in full force
              and effect to your use of this Website and by using it, you
              expressly accept, and be subject to, all terms and conditions
              contained herein in full. You must not use this Website, if you
              have any objection to any of these Website Standard Terms And
              Conditions.
            </p>
            <p>
              Copies of the ACDB will be kept for the purposes of data backup
              and an audit trail. The version of the Database that will be
              visible to users is referred to as the “Live Database”
            </p>
            <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
            <p>
              Other than content you own, which you may have opted to include on
              this Website, under these Terms, Box Actuarial Limited (“BAL”,
              “Us”) and/or its licensors own all rights to the intellectual
              property and material contained in this Website, and all such
              rights are reserved. You are granted a limited licence only,
              subject to the restrictions provided in these Terms, for purposes
              of viewing the material contained on this Website.
            </p>
            <h2> 3. RESTRICTIONS</h2>
            <p>
              You are expressly restricted from all of the following:
              <ol>
                <li>
                  engaging in any data mining, data harvesting, data extracting
                  or any other similar activity in relation to this Website, or
                  while using this Website;
                </li>
                <li>publishing any Website material in any media;</li>
                <li>
                  reproducing, adapting, uploading to a third party, linking to,
                  framing, performing in public, distributing, or transmitting
                  in any form by any process, the information on this website,
                  without our specific written consent, except where necessary
                  in order to view the information from this Website on your
                  browser, or as permitted under both English law and within
                  these Terms.
                </li>
                <li>
                  selling, sublicensing and/or otherwise commercialising any
                  Website material, including using this Website to engage in
                  any advertising or marketing;
                </li>
                <li>
                  publicly performing and/or showing any Website material;
                </li>
                <li>
                  using this Website in any way that is, or may be, damaging to
                  this Website;
                </li>
                <li>
                  using this Website in any way that impacts Y access to this
                  Website;
                </li>
                <li>
                  using this Website contrary to applicable laws and
                  regulations, or in a way that causes, or may cause, harm to
                  the Website, or to any person or business entity, whether that
                  business or person is connected to the use of the Website or
                  not;
                </li>
              </ol>
            </p>
            <p>
              Certain areas of this Website are restricted from access by you
              and BAL may further restrict access by you to any areas of this
              Website, at any time, in its sole and absolute discretion. Any
              user ID and password you may have for this Website are
              confidential and you must maintain confidentiality of such
              information.
            </p>
            <p>
              It is your obligation to keep your user details, including
              password, confidential and you should not disclose them to anyone
              else. You acknowledge and agree that any instruction or
              communication transmitted by you or on your behalf via our Website
              is made at your own risk. You authorise us to rely and act on, and
              treat as fully authorised and binding upon you, any instruction
              given to us that we believe to have been given by you or on your
              behalf by any agent or intermediary whom we believe in good faith
              to have been duly authorised by you. You acknowledge and agree
              that we shall be entitled to rely upon the details you have
              provided to identify you and you agree that you will not disclose
              this information to anyone not duly authorised by you.
            </p>
            <h2>4. TERMINATION</h2>
            <p>
              BAL reserves the right to remove your access to the Website, or
              restrict or amend the information shown on the Live Database, to
              you or to others, whether or not that information is related to
              you or others. BAL is under no obligation to accept any user on
              the Website.
            </p>
            <p>
              We have the right to permanently terminate your use of this
              website if we determine in our sole discretion that you have
              breached the terms and conditions, or have acted in a way that is
              detrimental to BAL or other users of the Website.
            </p>
            <h2>5. YOUR CONTENT</h2>
            <p>
              In these Website Standard Terms And Conditions, “Your Content”
              shall mean any audio, video, text, images or other material you
              choose to display on this Website. With respect to Your Content,
              by displaying it, you grant BAL a non-exclusive, worldwide,
              irrevocable, royalty-free, sublicensable licence to use,
              reproduce, adapt, publish, translate and distribute it in any and
              all media. BAL will from time to time amend any entries in the
              database which are incomplete, do not fully reflect reality or are
              incorrect. The determination of what constitutes reality or what
              is incorrect will be solely at the discretion of BAL.
            </p>
            <p>
              Your Content must be your own and must not be infringing on any
              third party’s rights. BAL reserves the right to remove any of Your
              Content from this Website at any time, and for any reason, without
              notice.
            </p>
            <h2>6. PRIVACY</h2>
            <p>
              We will comply with obligations imposed on us by applicable Data
              Protection legislation, including the UK Data Protection Act 1998.
              We shall use personal information provided by you in accordance
              with the implied intended use of that data, which is for finding
              potential contractors and/or for demonstrating your availability
              as a contractor.
            </p>
            <p>
              You agree that any information you put on the ACDB will be visible
              to other users. If you wish to be removed from the Live Database,
              please send an email to <Link to="/">admin@boxactuarial.com</Link>
              &nbsp;and BAL will endeavour to remove your information from the
              Live Database within 7 working days.
            </p>
            <h2>7. NO WARRANTIES</h2>
            <p>
              This Website is provided “as is,” with all faults, and BAL makes
              no express or implied representations or warranties, of any kind
              related to this Website or the materials contained on this
              Website. Additionally, nothing contained on this Website shall be
              construed as providing advice to you.
            </p>
            <p>
              BAL does not advocate for, or endorse, any user of the Database
              and does not provide any warranty that a particular user (whether
              a contractor, recruiter or employer) is suitable for any other
              user. You agree that it is incumbent on you to conduct your own
              suitability checks. In particular, the levels of skills and
              experience as indicated on the database are selected by
              individuals themselves and are not tested or verified by BAL.
            </p>
            <h2>8. LIMITATION OF LIABILITY</h2>
            <p>
              In no event shall BAL, nor any of its officers, directors and
              employees, be liable to you for anything arising out of or in any
              way connected with your use of this Website, whether such
              liability is under contract, tort or otherwise, and BAL, including
              its officers, directors and employees shall not be liable for any
              indirect, consequential or special liability arising out of or in
              any way related to your use of this Website.
            </p>
            <p>
              To the maximum extent permitted by law, we will not be liable in
              any way for any loss or damage suffered by you through use of or
              access to this website, or our failure to provide this website.
            </p>
            <h2>9. INDEMNIFICATION</h2>
            <p>
              You hereby indemnify to the fullest extent BAL from and against
              any and all liabilities, costs, demands, causes of action, damages
              and expenses (including reasonable legal fees) arising out of or
              in any way related to your breach of any of the provisions of
              these Terms.
            </p>
            <h2>10. SEVERABILITY</h2>
            <p>
              If any provision of these Terms is found to be unenforceable or
              invalid under any applicable law, such unenforceability or
              invalidity shall not render these Terms unenforceable or invalid
              as a whole, and such provisions shall be deleted without affecting
              the remaining provisions herein.
            </p>
            <h2>11. VARIATION OF TERMS</h2>
            <p>
              These terms and conditions can be modified at any time by us and
              you agree to continue to be bound by these terms and conditions as
              modified. We will give you notice of these changes by publishing
              revised terms and conditions on this website - we will not
              separately notify you of these changes.
            </p>
            <h2>12. ASSIGNMENT</h2>
            <p>
              BAL shall be permitted to assign, transfer, and subcontract its
              rights and/or obligations under these Terms without any
              notification or consent required. However, you shall not be
              permitted to assign, transfer, or subcontract any of your rights
              and/or obligations under these Terms.
            </p>
            <h2>13. ENTIRE AGREEMENT</h2>
            <p>
              These Terms, including any legal notices and disclaimers contained
              on this Website, constitute the entire agreement between BAL and
              you in relation to your use of this Website, and supersede all
              prior agreements and understandings with respect to the same.
            </p>
            <h2>14. GOVERNING LAW & JURISDICTION</h2>
            <p>
              These terms and conditions are governed by the law in force in
              England, and the parties irrevocably submit to the non-exclusive
              jurisdiction of the English courts for determination of any
              dispute concerning the terms and conditions.
            </p>
            <h2>15. RESPONSIBILITY OF VISITORS</h2>
            <p>
              The information on this website is not directed at residents of
              any particular country outside of the UK and is not intended for
              distribution to, or use by, any person in any country or
              jurisdiction where such distribution or use would be contrary to
              local law or regulation. It is the responsibility of visitors to
              this website to ascertain the terms of and comply with any local
              law or regulation to which they are subject.
            </p>
          </Col>
        </Row>
      </Container>
      <footer className="footer">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <p className="text-white text-center text-md-start">
                Copyright © 2023 Box Actuarial - All Rights Reserved.
              </p>
            </Col>
            <Col md={6}>
              <div className="text-end text-white footer-link">
                <a
                  href={arr ? arr[0]?.file : null}
                  target="_blank"
                  style={{ color: "#393939", textDecoration: "underline" }}
                >
                  terms & conditions
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download mx-1" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </TermsWrapper>
  );
};

export default TermsConditions;
