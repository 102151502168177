import axios, { AxiosResponse } from "axios";
import {
  requestStart,
  requestCompleted,
  requestSuccess,
  userLoggedIn,
  userLoggedOut,
  setVisibility,
  setDisplayName,
  setRole
} from "../..";
import { notification } from "antd";
import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import { GetItemsAction, GetLocationsAction } from "../Items";
import { AppRoutes } from "constants/routes";

export function SignupAction(
  data: { name: string; email: string; password: string; role: string },
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/register`, data)
        .then((res: AxiosResponse) => {
          if (res) {
            navigate(AppRoutes.otp.replace(":userId?", res.data.id), {
              state: data.email,
            });
            dispatch(requestCompleted());
            notification.success({
              message: "OTP sent successfully",
              description:
                "If you didn't get OTP in your email then please check your Spam folder for OTP",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: "Email already exists",
            description: "Please enter another email",
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function OTPAction(
  data: { otp: string; userId: string | undefined },
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/verify`, data)
        .then((res: AxiosResponse) => {
          if (res?.data) {
            navigate(AppRoutes.login);
            dispatch(requestCompleted());
            notification.success({
              message: "OTP verified successfully",
            });
          } else {
            dispatch(requestCompleted());
            notification.error({
              message: "OTP not verified",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function getAllUser(
  callback: (data: any) => void,
  navigate: NavigateFunction,
  column: any,
  skip: any
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/user?column=${column}&skip=${skip}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res: AxiosResponse) => {
          if (res?.data) {
            console.log(res?.data[0].data, "User data.");
            callback(res?.data[0]);
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          if (error.response.data.message == "Unauthorized") {
            localStorage.removeItem("token");
            navigate(AppRoutes.login);
          }
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function updateUser(id: any, data: any, notify: any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .patch(`${process.env.REACT_APP_API_BASE_URL}/user?id=${id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res: AxiosResponse) => {
          if (res?.data) {
            console.log(res?.data, "update the user")
            dispatch(setVisibility(res?.data.visibility));
            dispatch(setDisplayName(res?.data.displayName));
            dispatch(setRole(res?.data.activityRole));
            // getAllUser(getUser, navigate, 10, 0)
            if (notify) {
              notification.success({
                message: "User visibility updated Successfully!",
              });
            }
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ResendOTPAction(data: { email: string }) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/resend`, data)
        .then((res: AxiosResponse) => {
          if (res?.data) {
            // navigate("/otp");
            dispatch(requestCompleted());
            notification.success({
              message: "OTP Sent Again successfully",
            });
          } else {
            dispatch(requestCompleted());
            notification.error({
              message: "OTP not sent",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
export function LoginAction(
  data: { email: string; password: string },
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, data)
        .then((res: AxiosResponse) => {
          if (res?.data?.token?.user?.isVerified) {
            localStorage.setItem("token", res.data.token.accessToken);
            // localStorage.setItem("role", res.data.token.accessToken.user.role);
            dispatch<any>(GetLocationsAction());
            dispatch<any>(GetItemsAction(0, navigate));
            console.log({
              userData: {
                ...res.data.token.user,
                ...res.data.userInformation,
              },
              isRecordEntered: res.data.isRecordExist,
            }, "==============r");
            dispatch(
              userLoggedIn({
                userData: {
                  ...res.data.token.user,
                  ...res.data.userInformation,
                },
                isRecordEntered: res.data.isRecordExist,
              })
            );

            dispatch(requestCompleted());
            localStorage.setItem("UserType", res?.data?.token?.user?.role);
            if (res?.data?.token?.user?.role == "SUPER ADMIN") {
              console.log(res?.data?.token?.user?.role);
              navigate(AppRoutes.admin);
            } else {
              navigate(AppRoutes.portal);
            }

            notification.success({
              message: "Login successfully",
            });
          } else {
            dispatch(requestCompleted());
            notification.error({
              message: "Please enter valid credentials",
            });
          }
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          dispatch(requestCompleted());
          if (error?.response?.status === 401) {
            notification.error({
              message: error?.response?.data?.message,
              description: error?.response?.data?.description,
            });
          } else {
            notification.error({
              message: "Username or password does not match",
            });
          }
          console.log(error?.response?.data?.error);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function GetTermsAndCondition() {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/T&C`, {})
        .then((res: AxiosResponse) => {
          if (res) {
            console.log(res);
            //       localStorage.getItem("token");
            // dispatch<any>(GetLocationsAction());
            // dispatch<any>(GetItemsAction(0));
            dispatch(requestSuccess(res));
          }
        })
        .catch((error) => { });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function PostTermsAndCondition(obj: any, setLoading: any) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    const headers = {
      "Content-Type": "multipart/form-data", // Indicate you're sending a file
    };
    try {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/T&C/upload`, obj, {
          headers,
        })
        .then((res: AxiosResponse) => {
          if (res) {
            setLoading(false);

            notification.success({
              message:
                "File uploaded successfully. It may take few minutes for new content to update on the Terms & Conditions page.",
            });
          }
        })
        .catch((error) => { });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
export function LogoutAction(navigate: NavigateFunction) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    localStorage.removeItem("token");
    navigate(AppRoutes.login);
    dispatch(requestCompleted());
    dispatch(userLoggedOut());
  };
}

export function ContactAction(data: {
  name: string;
  email: string;
  message: string;
}) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        // .post(`http://localhost:3000/auth/contact-us`, data)
        .post(`${process.env.REACT_APP_API_BASE_URL}/auth/contact-us`, data)
        .then((res: AxiosResponse) => {
          if (res) {
            dispatch(requestCompleted());
            notification.success({
              message: "Email sent successfully",
              description: "We will contact you soon",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ForgetPasswordAction(
  data: { email: string },
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/auth/forget-password`,
          data
        )
        .then((res: AxiosResponse) => {
          if (res) {
            dispatch(requestCompleted());
            navigate(AppRoutes.linkSuccess);
            notification.success({
              message: "Email sent successfully",
              description: "Check your email for reset password link",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ResetPasswordAction(
  data: { password: string },
  token: string,
  navigate: NavigateFunction
) {
  return async (dispatch: Dispatch) => {
    dispatch(requestStart());
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: AxiosResponse) => {
          if (res) {
            dispatch(requestCompleted());
            navigate(AppRoutes.updated);
            notification.success({
              message: "Password updated successfully",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
          console.log(error?.response?.data?.message);
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
