import { useAppDispatch } from "Hooks/Store";
import { getAllUser, updateUser } from "Redux/App/Actions/Auth";
import { GetItemsAction } from "Redux/App/Actions/Items";
import { Table, Switch } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ManageUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({ data: [], total: 0 });
  const [updated, setUpdated] = useState([]);
  const [updatedUser, setUpdateUser] = useState();
  const getUsers = (res: any) => {
    setData(res);
    setUpdated(res.data);
  };
  useEffect(() => {
    dispatch<any>(getAllUser(getUsers, navigate, 10000, 0));
  }, []);

  const handleSwitchChange = (checked: any, key: any) => {
    console.log(updated, "updated");
    const updatedData: any = updated.map((item: any) =>
      item._id === key ? { ...item, visibility: !item.visibility } : item
    );

    setUpdated(updatedData);
    console.log(updatedData, "updatedData");
    // setData(updatedData);
  };
  useEffect(() => {
    console.log(updated);
  }, [updated]);
  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Profile Visibility",
      dataIndex: "visibility",
      key: "visibility",

      render: (text: any, record: any) => (
        <>
          <Switch
            checked={record?.visibility}
            onChange={(checked) => {
              handleSwitchChange(checked, record._id);
              dispatch<any>(updateUser(record._id, {}, true));
              // dispatch<any>(getAllUser(getUsers, navigate, 10, 0));
            }}
          />
        </>
      ),
    },
  ];

  return (
    <Table
      className="search-table"
      dataSource={updated}
      columns={columns}
      pagination={false}
      // pagination={{
      //   pageSize: data?.data?.length ? data?.data?.length : 10,
      //   total: data?.data?.length,
      //   showTotal: (total, range) =>
      //     `${range[0]}-${range[1]} of ${total} items`,
      //   showSizeChanger: true,
      //   pageSizeOptions: ["10", "30", "50"],
      //   defaultPageSize: 10,
      // }}
      onChange={(pagination) => {
        console.log(pagination);
        dispatch<any>(
          getAllUser(
            getUsers,
            navigate,
            pagination.pageSize,
            pagination.current
          )
        );
      }}
    />
  );
};

export default ManageUser;
