const portal = "/portal";
export const AppRoutes = {
  homeSlash: "/",
  portal: portal,
  login: `${portal}/login`,
  profileSetting: `${portal}/profile-settings`,
  personalInformation: `${portal}/personal-information`,
  softSkill: `${portal}/soft-skill`,
  expertise: `${portal}/expertise`,
  signUp: `${portal}/sign-up`,
  otp: `${portal}/otp/:userId?`,
  forgot: `${portal}/forgot-password`,
  reset: `${portal}/reset-password/:token?`,
  updated: `${portal}/success`,
  linkSuccess: `${portal}/link-success`,
  contact: `/contact`,
  termsConditions: `/terms`,
  admin: `/admin`,
};
