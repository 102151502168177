import React, { useState, useEffect, useLayoutEffect } from "react";
import { Layout, Menu } from "antd";
import { UserOutlined, CrownOutlined } from "@ant-design/icons";
import FileUpload from "../FileUpload";
import ManageUser from "../ManageUser";
import { AdminWrapper } from "./styels";
import { AppRoutes } from "constants/routes";
import { useNavigate } from "react-router-dom";

const { Sider, Content } = Layout;

const AdminPage = () => {
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("admin");

  const handleMenuClick = (menuKey: any) => {
    setSelectedMenuItem(menuKey);
  };

  useEffect(() => {
    if (localStorage.getItem("UserType") == "SUPER ADMIN") {
      navigate(AppRoutes.admin);
    } else {
      navigate(AppRoutes.portal);
    }
  }, [1000]);

  return (
    <AdminWrapper>
      <Layout style={{ height: "100vh" }}>
        <Sider width={280} theme="dark">
          <Menu
            mode="vertical"
            selectedKeys={[selectedMenuItem]}
            style={{
              height: "100%",
              borderRight: "1px solid #E8E8E8",
              padding: "10px",
            }}
            onClick={({ key }) => handleMenuClick(key)}
          >
            <Menu.Item
              key="admin"
              // icon={<CrownOutlined />}
              style={{
                background:
                  selectedMenuItem === "admin" ? "#001529" : "#F7F7F7",
                color: selectedMenuItem === "admin" ? "#fff" : "#1C1C1C",
                textAlign: "center",
                borderRadius: "6px",
              }}
            >
              Manage Users
            </Menu.Item>
            <Menu.Item
              key="user"
              // icon={<UserOutlined />}
              style={{
                background: selectedMenuItem === "user" ? "#001529" : "#F7F7F7",
                color: selectedMenuItem === "user" ? "#fff" : "#1C1C1C",
                borderRadius: "6px",
                textAlign: "center",
              }}
            >
              System Settings
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ overflow: "auto" }}>
          <Content style={{ padding: "24px" }}>
            {selectedMenuItem === "admin" ? (
              <div>
                <ManageUser />
              </div>
            ) : (
              <div>
                <FileUpload />
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
    </AdminWrapper>
  );
};

export default AdminPage;
