import { FileUploadWrapper } from "./styles";
import { useAppDispatch, useAppSelector } from "Hooks/Store";
import {
  GetTermsAndCondition,
  LogoutAction,
  PostTermsAndCondition,
} from "Redux/App/Actions/Auth";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Image, message, Row, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useState } from "react";
import PdfIcon from "Assets/Icons/PdfIcon";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { data } from "Redux/App";

const formatDate = (dateStr: any) => {
  if (!dateStr) return null;
  const date = new Date(dateStr);
  return date.toISOString().split("T")[0];
};
const extractFileNameFromUrl = (url: any) => {
  if (typeof url === "string") {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }
  return null;
};

const FileUpload: React.FC = () => {
  const [file, setfile] = useState("");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const [arr, setArr] = useState<any>(null);
  const termsAndCond = useSelector(data);
  useEffect(() => {
    dispatch<any>(GetTermsAndCondition());
    getData();
  }, [1000]);
  const getData = () => {
    setArr(termsAndCond.data);
  };

  const props = {
    name: "file",
    action: `${process.env.REACT_APP_API_BASE_URL}/T&C/upload`,
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false,
    beforeUpload: (file: any) => {
      setLoading(true);
      // Check if the file is a PDF
      if (file.type !== "application/pdf") {
        message.error("You can only upload PDF files.");
        setLoading(false); // Stop loading
        return false; // Prevent the file from being uploaded
      }
      const formData = new FormData();
      delete file?.uid;
      formData.append("file", file);
      dispatch<any>(PostTermsAndCondition(formData, setLoading));
      return false;
    },
  };

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <CloudUploadOutlined style={{ fontSize: "32px" }} />
      )}
      {loading ? (
        <div style={{ marginTop: 8 }}>Uploading...</div>
      ) : (
        <div style={{ marginTop: 8 }}>
          <p className="file-name">Upload Terms and Condition</p>
          <p className="file-date">Supported file type : PDF</p>
        </div>
      )}
    </div>
  );

  return (
    <>
      <FileUploadWrapper>
        <Row>
          <Col md={6}>
            <Upload
              {...props}
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${process.env.REACT_APP_API_BASE_URL}/T&C/upload`}
              accept=".pdf"
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card>
              <div className="tc-file-upload">
                <PdfIcon />
                <div>
                  <p className="file-name">
                    {arr ? extractFileNameFromUrl(arr[0]?.file) : null}
                  </p>
                  <p className="file-date">
                    {" "}
                    {formatDate(arr ? arr[0]?.date : null)}
                  </p>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </FileUploadWrapper>
      {/* <button style={{ border: "1px solid black", borderRadius: "0px" }} className="btn btn-dark m-2 text-light " onClick={() => { dispatch<any>(PostTermsAndCondition({ file: file },)); }}>Add T&C</button> */}
    </>
  );
};

export default FileUpload;
