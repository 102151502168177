import { AppRoutes } from "constants/routes";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FooterWrapper } from "./styles";
import { GetTermsAndCondition } from "Redux/App/Actions/Auth";
import { data } from "Redux/App";
import { useAppDispatch } from "Hooks/Store";
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
const Footer = () => {
  const [arr, setArr] = useState<any>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch<any>(GetTermsAndCondition());
    getData()
  }, [1000])
  const termsAndCond = useSelector(data);

  const getData = () => {
    console.log(termsAndCond.data, "kkk")
    setArr(termsAndCond.data)
  }

  // console.log(arr[0].file, "file")
  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      <FooterWrapper>
        <footer style={{ marginTop: '6rem' }} className="footer">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <p className="text-white text-center text-md-start">
                  Copyright © 2023 Box Actuarial - All Rights Reserved.
                </p>
              </Col>
              <Col md={6}>
                <div className="text-end text-white footer-link">
                  <a
                    href={arr ? arr[0]?.file : null}
                    target="_blank"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Terms & Conditions
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </FooterWrapper>
    </div>
  );
};

export default Footer;
