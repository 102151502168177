import { Button, Card, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { HomeWrapper } from "./styles";
import service_img_1 from "../../Assets/Images/services_img_1.png";
import service_img_2 from "../../Assets/Images/services_img_2.png";
import service_img_3 from "../../Assets/Images/services_img_3.png";
import service_img_4 from "../../Assets/Images/services_img_4.png";
import { Link } from "react-router-dom";
import NavBarLanding from "./NavBarLanding";
import { AppRoutes } from "constants/routes";
import { NavLink } from "react-router-dom";
import Footer from "Components/Shared/Footer";
import { GetItemsAction } from "Redux/App/Actions/Items";
import { useEffect } from 'react'
import { useAppDispatch } from "Hooks/Store";
import { useNavigate } from "react-router-dom";

export const HomeSlash = () => {
  const isAuthenticated = localStorage.getItem("token");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   dispatch<any>(GetItemsAction(0, navigate));
  // }, []);
  return (
    <>
      <HomeWrapper>
        {/* =========Hero Section================= */}
        <section className="hero-section" id="home">
          <div className="overlay">
            <NavBarLanding bg="rgba(0, 0, 0, 0.8)" />
            <Container>
              <Row>
                <Col md={6}>
                  <h1 className="text-white hero-heading">
                    Experienced Actuarial Support to the General Insurance
                    Sector
                  </h1>
                  <p className="text-white">
                    Helping you lead the way in Pricing & Underwriting
                  </p>
                  <a href="#service">
                    <Button className="cta-button mb-5">
                      Find out what we specialise in
                    </Button>
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/* ===================What we do================= */}
        <section className=" pt-5" id="service">
          <Container>
            <Row>
              <Col md={12}>
                <h2 className="sub-heading pt-5">What We Do</h2>
              </Col>
              <Col md={3} className="mt-4 d-flex">
                <Card className="border-0">
                  <Card.Img variant="top" src={service_img_1} />
                  <Card.Body>
                    <h3 className="text-center card-heading mt-3 mb-3 px-5">
                      About Us
                    </h3>
                    <Card.Text>
                      We help our General Insurance clients to improve their
                      pricing processes, enabling their move away from pricing
                      that was fit for last century to pricing techniques that
                      will keep them profitable in the coming decades. If this
                      is your goal, we can make this a reality and our experts
                      work pragmatically to help you do that. We're prepared to
                      help you at any level, from technical pricing support to
                      major process overhauls of the Pricing Function
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="mt-4 d-flex">
                <Card className="border-0">
                  <Card.Img variant="top" src={service_img_2} />
                  <Card.Body>
                    <h3 className="text-center card-heading mt-3 mb-3 px-5">
                      Areas of Focus
                    </h3>
                    <Card.Text>
                      Particular areas of expertise include:
                      <ul>
                        <li>Pricing Analysis</li>
                        <li>Data Analysis</li>
                        <li>Pricing Design</li>
                        <li>Portfolio Management</li>
                        <li>Generalised Linear Modelling</li>
                        <li>Upgrading to AI techniques</li>
                        <li>Delivery Mechanisms and Design</li>
                        <li>
                          Assessment of 3rd Party Software and Advice on
                          Appropriate Selection
                        </li>
                      </ul>
                      We can work with you on a consultancy basis, or through
                      service contracts.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="mt-4 d-flex">
                <Card className="border-0">
                  <Card.Img variant="top" src={service_img_3} />
                  <Card.Body>
                    <h3 className="text-center card-heading mt-3 mb-3 px-5">
                      Actuarial Contractor Database
                    </h3>
                    <Card.Text>
                      Coming Soon <br /> We are working on a database that will
                      allow contractors to alert employers as to their
                      availability and expertise, as well as allowing employers
                      to search for available contractors to support them. This
                      will be a free-to-use service on both sides.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="mt-4 d-flex">
                <Card className="border-0">
                  <Card.Img variant="top" src={service_img_4} />
                  <Card.Body>
                    <h3 className="text-center card-heading mt-3 mb-3 px-5">
                      Pricing & Reserving Software
                    </h3>
                    <Card.Text>
                      Box Actuarial are currently developing software for
                      Pricing, using modern programming languages and
                      incorporating artificial intelligence techniques, which
                      will be cheap and accessible for modern actuaries. This
                      software will also incorporate reserving functionality,
                      expanding on the availability of AI and stochastic
                      techniques.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="mt-5 pt-5 ">
          <Container>
            <Row>
              <Col md={12}>
                <h2 className="sub-heading">About Box Actuarial</h2>
              </Col>
              <Col md={4} className="d-flex mt-4">
                <Card className="about-card">
                  <Card.Body>
                    <h3 className="card-heading">Our Experience</h3>
                    The company is led by Gerard Walls, a qualified actuary with
                    over 20 years of experience in the Insurance industry.
                    <br />
                    Gerard was previously at PwC, where he headed the pricing
                    and underwriting team within the actuarial services area.
                    <br />
                    Gerard has also held a number of other senior positions,
                    including:
                    <ul>
                      <li>Head of Operational Pricing at a Motor Insurer</li>
                      <li>Head of Global Pricing at QBE</li>
                      <li>
                        Acted as Head of Commercial Pricing & Analytics at Axa
                        UK
                      </li>
                      <li>Head of Pricing at Co-op</li>
                      <li>Head of Pricing at RSA Ireland</li>
                    </ul>
                    <br />
                    Other previous clients include:
                    <ul>
                      <li>Travelers UK</li>
                      <li>Direct Line Group </li>
                      <li> Zurich Group </li>
                      <li>RSA UK </li>
                      <li>LV </li>
                      <li>Aviva </li>
                      <li>Flood Re</li>
                      <li> XS Direct</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="d-flex mt-4">
                <Card className="about-card">
                  <Card.Body>
                    <h3 className="card-heading">Our Approach</h3>
                    We understand that change doesn't happen easily and can be
                    complicated. 'Big Bang' changes are often problematic,
                    costly, and sometimes leads to value destruction. We believe
                    that material, but gradual and incremental changes need to
                    happen and that this needs to be backed up by a longer term,
                    strategic vision so that all areas of the insurance
                    operation move forward with minimal friction between, and
                    within, teams. This can be politically problematic and one
                    of our key strengths is working with companies at board
                    level and senior executive level to explain requirements,
                    suggest proportionate approaches and agree sensible budgets.
                    We are careful neither to ask for, nor promise, the earth,
                    but to work collaboratively to set out an approach which
                    works for an insurers' particular (and real world) position.
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="d-flex mt-4">
                <Card className="border-0">
                  <Card.Body>
                    <h3 className="card-heading">Why Us?</h3>
                    The Box Actuarial team has worked with many insurers and
                    many third-party suppliers of both software and consultancy
                    companies. We can work with our clients on an agnostic basis
                    to advise them as to which third-parties are most suitable
                    for their particular circumstances - particularly where
                    long-term software decisions need to be made.
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="mt-5 pt-5 help-container">
          <Container>
            <Row>
              <Col md={12}>
                <h2 className="sub-heading">How can we help?</h2>
                <h3 className="mt-5">Predictive Modelling</h3>
                <p>As a process to be designed, not a task to be performed</p>
                <Row>
                  <Col md={6}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <h3>Data Collection & Cleaning</h3>
                          </Col>
                          <Col xs={3}>
                            <p className="small-text">Yes</p>
                          </Col>
                        </Row>
                        <ul>
                          <li>Finding & linking internal data</li>
                          <li>
                            Data Reconciliations - identifying comparables and
                            setting up process
                          </li>
                          <li>Identifying and linking third-party data sets</li>
                          <li>
                            Investigation, understanding, fixing and
                            documentation of inconsistencies
                          </li>
                          <li> Linking claims and exposure </li>
                          <li> Engineering & Enhancements</li>
                          <li> Mapping & Transformations</li>
                          <li> 'Push button' development</li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <h3>Primary Analysis</h3>
                          </Col>
                          <Col xs={3}>
                            <p className="small-text">Yes</p>
                          </Col>
                        </Row>
                        <ul>
                          <li>Identifying exposure measures and key drivers</li>
                          <li>Quick wins</li>
                          <li>Structural design of models</li>
                          <li>Automation</li>
                        </ul>
                      </Card.Body>
                    </Card>
                    <Card className="mt-4">
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <h3>Statistical Analysis</h3>
                          </Col>
                          <Col xs={3}>
                            <p className="small-text">Yes</p>
                          </Col>
                        </Row>
                        <ul>
                          <li>GLMs</li>
                          <li>Review and challenge</li>
                          <li>Underwriter education and agreement</li>
                          <li>Automation</li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <h3 className="mt-5">Design and Build of MI Systems & KPIs</h3>
                <p>Add a description about this category</p>
                <Row>
                  <Col md={6}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <h3>Designing MI</h3>
                          </Col>
                          <Col xs={3}>
                            <p className="small-text">Yes</p>
                          </Col>
                        </Row>
                        <ul>
                          <li>Understanding needs, purposes and targets</li>
                        </ul>
                      </Card.Body>
                    </Card>
                    <Card className="mt-4">
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <h3>Development of MI Dashboards & Reports</h3>
                          </Col>
                          <Col xs={3}>
                            <p className="small-text">Yes</p>
                          </Col>
                        </Row>
                        <ul>
                          <li>Setting up data feeds</li>
                          <li>
                            Working with you to create automation of reports
                          </li>
                          <li>Production</li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <h3>Impact analysis</h3>
                          </Col>
                          <Col xs={3}>
                            <p className="small-text">Yes</p>
                          </Col>
                        </Row>
                        <ul>
                          <li>
                            Ensuring processes are in place to react to output
                          </li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <h3 className="mt-5">Key Pricing Components</h3>
                <p>
                  We can help with many of the add-on parts that are important
                  in pricing work
                </p>
                <Row>
                  <Col md={6}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <h3>Model Add-Ons</h3>
                          </Col>
                          <Col xs={3}>
                            <p className="small-text">Yes</p>
                          </Col>
                        </Row>
                        <ul>
                          <li>Cat modelling</li>
                          <li>Large loss loads</li>
                          <li>Area rating</li>
                          <li>Vehicle analysis</li>
                          <li> Occupation analysis </li>
                          <li> Base Setting </li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <h3>
                              Delivery Tool Selection, Design & Installation
                            </h3>
                          </Col>
                          <Col xs={3}>
                            <p className="small-text">Yes</p>
                          </Col>
                        </Row>
                        <ul>
                          <li>Batch Testing</li>
                          <li>Implementation</li>
                        </ul>
                      </Card.Body>
                    </Card>
                    <Card className="mt-4">
                      <Card.Body>
                        <Row>
                          <Col xs={9}>
                            <h3>Aggregation management</h3>
                          </Col>
                          <Col xs={3}>
                            <p className="small-text">Yes</p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        {/* ==========footer */}
        {/* <footer className="footer">
          <Container>
            <Row className="justify-content-center">
              <Col md={6}>
                <p className="text-white text-center text-md-start">
                  Copyright © 2023 Box Actuarial - All Rights Reserved.
                </p>
              </Col>
              <Col md={6}>
                <div className="text-end text-white footer-link">
                  <NavLink
                    to={AppRoutes.termsConditions}
                    className="text-decoration-none text-white ml-4"
                  >
                    Terms & Conditions
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </footer> */}
        <Footer />
      </HomeWrapper>
    </>
  );
};
