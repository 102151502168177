import { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Checkbox, Radio } from "antd";
import { LoginCard, LoginWrapper, FormWrapper } from "./styles";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { SignupAction } from "Redux/App/Actions/Auth";
import { loading as stateLoading } from "Redux/App";
import { useAppDispatch } from "Hooks/Store";
import BoxActaurialAuthIcon from "Assets/Logos/BoxActaurialAuthIcon";
import { AppRoutes } from "constants/routes";
import { data } from "Redux/App";
import { GetTermsAndCondition } from "Redux/App/Actions/Auth";
import type { RadioChangeEvent } from "antd";
const Login = () => {
  const navigate = useNavigate();
  const loading = useSelector(stateLoading);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [file, setfile] = useState("");
  useEffect(() => {
    let authToken = sessionStorage.getItem("Auth Token");
    let role = sessionStorage.getItem("role");
    if (authToken) {
      //   navigate(AppRoutes.admin);
      // } else {
      navigate(AppRoutes.portal);
    }
  }, [navigate]);
  const [value, setValue] = useState(1);
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const onFinish = (values: any) => {
    values["role"] = "ADMIN";
    values.activityRole = value;
    dispatch<any>(SignupAction(values, navigate));
  };
  const [checked, setChecked] = useState(false);
  const onCheckboxChange = async (e: any) => {
    await setChecked(e.target.checked);
    form.validateFields(["checkbox"]);
  };
  const submit = async () => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const validation = (
    rule: any,
    value: any,
    callback: (error?: string) => void
  ) => {
    if (checked) {
      return callback();
    }
    return callback("Please accept the terms and conditions");
  };

  const [arr, setArr] = useState<any>(null);
  const termsAndCond = useSelector(data);
  useEffect(() => {
    dispatch<any>(GetTermsAndCondition());
    getData();
  }, [1000]);
  const getData = () => {
    console.log(termsAndCond.data, "kkk");
    setArr(termsAndCond.data);
  };
  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center", marginBottom: "22px" }}>
              <BoxActaurialAuthIcon />
            </div>
          </Col>
        </Row>

        <h1 className="form-primary-heading">Create an account</h1>
        {/* <p className="form-text ">This won’t take long</p> */}
        <FormWrapper>
          <Form
            form={form}
            layout="vertical"
            className="FormWrapper"
            onFinish={onFinish}
          >
            {loading ? <div className="overlay"></div> : ""}
            <Form.Item
              name="fullName"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="displayName"
              extra="Note that this name is what will be displayed to users of the website and will be used by recruiters to find you"
              rules={[
                { required: true, message: "Please enter your Display name" },
              ]}
            >
              <Input placeholder="Display name" />
            </Form.Item>
            <Form.Item name="role" label="Role">
              <Radio.Group
                onChange={onChange}
                value={value}
                className=""
                defaultValue="Contractor"
              >
                <Radio value="Recruiter">Recruiter</Radio>
                <Radio value="Employer">Employer</Radio>
                <Radio value="Contractor">Contractor</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please enter your email" }]}
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>
            {/* <Form.Item
              name="file"
              rules={[
                { required: true, message: "Please enter upload file" },

              ]}
            >
              <Input className="pt-2" type="file" onChange={(e) => {
                console.log(e.target.files)
                const fileList: any = e.target.files
                const file = fileList[0];
                const reader = new FileReader();
                reader.onload = (event: any) => {
                  const base64String = event.target.result.split(',')[1]; // Extract the Base64 data
                  console.log(base64String);
                  setfile(base64String) // This is the Base64 representation of the file
                };
                reader.readAsDataURL(file);
              }} />
            </Form.Item> */}
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
                {
                  min: 5,
                  message: "Password must be minimum 5 characters long",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your password again",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("The password does not match");
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>

            <Form.Item name="checkbox" rules={[{ validator: validation }]}>
              <Checkbox checked={checked} onChange={onCheckboxChange}>
                I accept the&nbsp;
                {/* {arr[0]?.file ? */}
                <a
                  href={arr ? arr[0]?.file : null}
                  target="_blank"
                  style={{ color: "#393939", textDecoration: "underline" }}
                >
                  terms & conditions
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download mx-1" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg> */}
                </a>
                {/* : null} */}
              </Checkbox>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }}>
              <div style={{ textAlign: "center" }}>
                <Button
                  className="form-submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={submit}
                >
                  Signup
                </Button>
              </div>
            </Form.Item>
            <p>
              <span style={{ color: "#BBBBBB" }}>
                Already have an account?&nbsp;
              </span>
              <Link
                to={AppRoutes.login}
                style={{ color: "#393939", textDecoration: "underline" }}
              >
                Login
              </Link>
            </p>
          </Form>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default Login;
