import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import {
  WellComeBoxActaurial,
  DashBoardWrapper,
  SettingHeading,
  SettingPrimaryHeading,
  HeadingText,
} from "./styles";
import { UserHeading } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Home = () => {
  const { state } = useLocation();
  let softwares = [];
  // if (state?.softwares) {
  //   softwares = JSON.parse(state.softwares);
  // }
  const navigate = useNavigate();

  const onBackPress = () => {
    navigate(-1);
  };

  return (
    <DashBoardWrapper>
      <WellComeBoxActaurial>
        <Button
          className="back-btn"
          icon={<ArrowLeftOutlined color={"#393939"} />}
          type="text"
          onClick={onBackPress}
        >
          Back
        </Button>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            background: "#fff",
            padding: 20,
            alignItems: "baseline",
          }}
        >
          <div style={{ display: "flex", gap: 20 }}>
            <Avatar size={64} icon={<UserOutlined />} />
            <div>
              <UserHeading>Welcome {state?.name}!</UserHeading>
              <div>{state?.email}</div>
            </div>
          </div>
        </div> */}

        <div>
          <SettingHeading>Personal information</SettingHeading>
          <div style={{ display: "flex", gap: 30 }}>
            <SettingPrimaryHeading>Full Name</SettingPrimaryHeading>
            <HeadingText>{state?.fullName}</HeadingText>
          </div>
          <div style={{ display: "flex", gap: 30 }}>
            <SettingPrimaryHeading>Display Name</SettingPrimaryHeading>
            <HeadingText>{state?.displayName}</HeadingText>
          </div>

          <div style={{ display: "flex", gap: 30 }}>
            <SettingPrimaryHeading>Location</SettingPrimaryHeading>
            <HeadingText>
              {/* {state?.baseLocation} */}
              {state?.baseLocation.map((item: any) => `${item}, `)}
            </HeadingText>
          </div>
        </div>

        <div>
          <SettingHeading>Expertise</SettingHeading>

          <div style={{ display: "flex", gap: 30 }}>
            <SettingPrimaryHeading>Area of expertise</SettingPrimaryHeading>
            {/* {state?.areaOfExpertise?.map((item: any) => { */}
            <HeadingText key={state?.areaOfExpertise}>
              {state?.areaOfExpertise}
            </HeadingText>
            {/* })} */}
          </div>

          <div style={{ display: "flex", gap: 30 }}>
            <SettingPrimaryHeading>Area of Speciality</SettingPrimaryHeading>
            <div
              style={{
                display: "-webkit-box",
                gap: 30,
                overflowX: "scroll",
                width: "80%",
              }}
            >
              {state?.areaOfSpeciality?.map((item: any) => {
                return <HeadingText key={item}>{item}</HeadingText>;
              })}
            </div>
          </div>

          <div style={{ display: "flex", gap: 30 }}>
            <SettingPrimaryHeading>
              Enter your Qualification
            </SettingPrimaryHeading>
            <HeadingText>{state?.qualification}</HeadingText>
          </div>

          <div style={{ display: "flex", gap: 30 }}>
            <SettingPrimaryHeading>Years PQE?</SettingPrimaryHeading>
            <HeadingText>{state?.pqe}</HeadingText>
          </div>

          <div style={{ display: "flex", gap: 30 }}>
            <SettingPrimaryHeading>Years of experience</SettingPrimaryHeading>
            <HeadingText>{state?.totalYears}</HeadingText>
          </div>

          <div>
            <SettingHeading>Software skills</SettingHeading>

            <div style={{ display: "flex", gap: 30 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SettingPrimaryHeading>Software</SettingPrimaryHeading>
                <SettingPrimaryHeading>Efficiency</SettingPrimaryHeading>
              </div>
              <div
                style={{
                  display: "-webkit-box",
                  gap: 30,
                  overflowX: "scroll",
                  width: "80%",
                }}
              >
                {state?.softwares.map((item: any) => {
                  return (
                    item.isSelected && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <HeadingText>{item.value}</HeadingText>
                        <HeadingText>{item.grading}</HeadingText>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </WellComeBoxActaurial>
    </DashBoardWrapper>
  );
};

export default Home;
